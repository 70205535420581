import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useGymCoachInfo } from '../../content/projects/gymcoach.project';
import { useAppContext } from '../../controllers/app.controller';
import { isBrowser } from '../../env';
import { getEntryPagePath } from '../../utils/entry.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import EntryMetaInfoHeader from '../EntryMetaInfoHeader/EntryMetaInfoHeader';
import GatsbyImageAdaptor from '../GatsbyImageAdaptor/GatsbyImageAdaptor';
import './GymCoachShowcaseEntry.scss';

type GymCoachShowcaseEntryProps = {}

const GymCoachShowcaseEntry: React.FC<GymCoachShowcaseEntryProps> = props => {
  const gymCoach = useGymCoachInfo();
  const { UI } = useAppContext();
  return <Observer children={() => (
    <AnimatedLink className="GymCoachShowcaseEntry" title="GymCoach Case Study" to={getEntryPagePath(gymCoach)}>
      <div className="GymCoachShowcaseEntryInner">
        <GatsbyImageAdaptor className="GymCoachShowcaseEntryBackground" image={gymCoach.featuredImage} />
        <header className="GymCoachShowcaseEntryHeader">
          <EntryMetaInfoHeader entry={gymCoach} doNotAutoLink />
        </header>
        <figure className="GymCoachShowcaseEntryMockupSet">
          <GatsbyImageAdaptor
            className="GymCoachShowcaseEntryMobileMockup"
            image={gymCoach.mobileScreenshots![0]}
            objectFit={isBrowser && UI.viewport.width < 640 ? 'fill' : 'none'}
          />
          <GatsbyImageAdaptor
            className="GymCoachShowcaseEntryMobileMockup"
            image={gymCoach.mobileScreenshots![1]}
            objectFit={isBrowser && UI.viewport.width < 640 ? 'fill' : 'none'}
          />
        </figure>
      </div>
    </AnimatedLink>
  )} />
}

export default GymCoachShowcaseEntry;